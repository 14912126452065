import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    padding: 22px 15px;
    font-size: x-large;
  }

  @media (min-width: 1023px) {
    display: none;
  }

  @media (max-width: 1023.1px) {
    flex-flow: column nowrap;
    background-color: black;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    margin: 0;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
`;

export default function NavMenu(props) {
  const setOpen = props.setOpen;
  const open = props.open;

  return (
    <Ul open={props.open}>
      <div></div>
      <NavLink exact to={"/"}>
        <li onClick={() => setOpen(!open)} className="gold text-2xl s2s-header font-bold underline-none">
          HOME
        </li>
      </NavLink>

      <NavLink exact to={"/biography"}>
        <li onClick={() => setOpen(!open)} className="gold text-2xl s2s-header font-bold underline-none">
          BIOGRAPHY
        </li>
      </NavLink>

      <NavLink exact to={"/demo"}>
        <li onClick={() => setOpen(!open)} className="gold text-2xl s2s-header font-bold underline-none">
          DEMO REEL
        </li>
      </NavLink>

      <NavLink exact to={"/films"}>
        <li onClick={() => setOpen(!open)} className="gold text-2xl s2s-header font-bold underline-none">
          FILMS
        </li>
      </NavLink>

      <NavLink exact to={"/contact"}>
        <li onClick={() => setOpen(!open)} className="gold text-2xl s2s-header font-bold underline-none">
          CONTACT
        </li>
      </NavLink>
    </Ul>
  );
}
