import React, {useEffect} from "react";
import "../../node_modules/video-react/dist/video-react.css";
import animation from "../Videos/S2SIntro.mov";

const Home = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  return (
    <div className="pt-20 bg-black realative">
        <div className="flex justify-center">
          <video className="z-0 justify-self-center" style={{height:"90vh"}} autoPlay muted playsInline>
            <source src={animation} type="video/mp4" />
          </video>
        </div>
    </div>
  );
};

export default Home;
