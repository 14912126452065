import React, {useEffect} from "react";
import directorPic from "../Images/director.jpeg";
import soulbio from "../Images/soulbio.jpg";

const Bio = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    });
  return (
    <div className="goldbg pt-20">
      <div className="pt-5 text-5xl text-center s2s-header">BIOGRAPHY</div>
      <div className="flex justify-center flex-col-reverse md:grid md:grid-cols-6 pt-5 px-10 items-center">
        <div className="px-5 flex justify-center md:col-span-3">
          <img className="rounded self-start max-h-screen" src={directorPic} alt="" />
        </div>
        <div className="p-3 flex-1 md:col-span-3 text-lg s2s-text self-start">
          I am &quot;Soul&quot; a New Yorker from the Bronx and longtime
          resident of Brooklyn. I spent most of my career working in schools
          supporting youth as a Social Worker and an Assistant Principal. Now, I
          am educating and supporting youth and adults through visual
          storytelling. I am a Director, Writer and
          Producer.
        </div>
      </div>
      <div className="md:pt-5 md:grid md:grid-col-reverse md:grid-cols-6 px-10 items-center">
        <div className="py-3 justify-center flex-1 text-lg md:col-span-3 s2s-text self-start">
          However...deep down inside, I always had a desire to express my
          creatively. I took private acting classes and felt my creative desire
          grow. My drama teacher, Nathan George became my life coach, mentor and
          second father, he was a powerful influence in my life. He helped me to
          understand the power of film, directing and acting can have in
          millions of people&#39;s lives. Nathan passed away and his death
          devastated my life, it made me think and ask myself, &quot;if I died
          today, would I feel like my life is fulfilled&quot;, have I truly
          lived my purpose?&quot; The honest answer was NO! I decided to live my
          life as a YES, so I followed my dream and passion to become a
          filmmaker.
        </div>
        <div className="p-3 flex justify-center md:col-span-3 self-start">
          <img className="rounded max-h-screen " src={soulbio} alt="" />
        </div>
      </div>
      <div className="py-3 px-12 md:px-32 text-lg s2s-text">
        Thus far, I have directed five short films, two PSA’s and two music
        videos. The PSA, You Matter in conjunction with my non-profit Soul2Soulz
        (www.soul2soulz.org) focuses on challenging each other to treat the
        people we encounter everyday like they Matter.
      </div>
    </div>
  );
};

export default Bio;
