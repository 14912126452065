import React from 'react';
import Navbar from './components/Navbar.jsx'
import Main from "./components/Main.jsx"
import Footer from './components/Footer'
import './index.css';
import './styles/styles.scss'


function App() {
  return (
    <div>
      <Navbar />
      <Main />
      <Footer />
    </div>
  );
}

export default App;