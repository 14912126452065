import React, {useEffect} from 'react';
import { Player } from "video-react";
import reel from "../Videos/soulDemoReel.mp4"
import logo from "../Images/logo.png"


const Demo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
    return (
        <div className="pt-20 flex flex-col min-h-screen goldbg font-black">
        <div className="text-5xl py-10 self-center s2s-header">Demo Reel</div>
        <div className="w-screen h-auto px-10 self-center pb-10 lg:w-1/2">
          <Player src={reel} poster={logo} />
        </div>
      </div>
    );
}

export default Demo;