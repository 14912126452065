import React, {useEffect} from "react";
import { movieData } from "./movieData";
import { Player } from "video-react";

const MovieDetails = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const currentMovie = movieData[props.match.match.params.id - 1];
  return (
    <div className="pt-20 flex flex-col min-h-screen goldbg font-black">
      <div className="text-5xl py-10 self-center s2s-header">{currentMovie.title}</div>

      <div className="w-screen h-auto px-10 lg:w-1/2 self-center">
        <Player src={currentMovie.video} poster={currentMovie.image} />
      </div>

      <div className="p-10 text-center lg:px-32 s2s-text">
        {currentMovie.description}
      </div>
    </div>
  );
};

export default MovieDetails;
