import singleDadPic from "../Images/singleDad.png";
import unspeakablePic from "../Images/unspeakable.png";
import prejudicePic from "../Images/prejudice.png";
import foundItPic from "../Images/foundIt.png";
import notWhatTheySeemPic from "../Images/notWhatTheySeem.png";
import oneSizePic from "../Images/sizeDoesNotFit.png";
import cameToPartyPic from "../Images/cameToParty.png";
import youMatterPic from "../Images/youMatter.png";

import singleDadVid from "../Videos/singleDad.mp4";
import unspeakableVid from "../Videos/unspeakable.mp4";
import prejudiceVid from "../Videos/prejudice.mp4";
import foundItVid from "../Videos/foundIt.mov";
import notWhatTheySeemVid from "../Videos/notWhatTheySeem.mp4";
import oneSizeVid from "../Videos/sizeDoesNotFit.mp4";
import cameToPartyVid from "../Videos/cameToParty.MP4";
import youMatterVid from "../Videos/youMatter.mov";

export const movieData = [
  {
    id: 1,
    title: "Single Life Dad?",
    image: singleDadPic,
    video: singleDadVid,
    year: "2018",
    description:
      "The short, Single Life Dad? is a story about a young man’s single life ends abruptly when the six year old son he didn’t know that he had, is abandoned in his care. He must decide if he will go back to living his single life or commit to being a single dad.",
  },
  {
    id: 2,
    title: "Unspeakable",
    image: unspeakablePic,
    video: unspeakableVid,
    year: "2018",
    description:
      "Unspeakable is a compelling story about a School Social Worker and a School Community, coming together to save a student in an extremely abusive situation.  Unspeakable has also been accepted into film festivals.",
  },
  {
    id: 3,
    title: "Prejudice to Acceptance",
    image: prejudicePic,
    video: prejudiceVid,
    year: "2018",
    description:
      "Prejudice to Acceptance show an unfiltered, non-politically correct discussion between a Black Woman and White Woman about the misconceptions White people may have about Black People.",
  },
  {
    id: 4,
    title: "I found it in me",
    image: foundItPic,
    video: foundItVid,
    year: "2018",
    description:
      "I found it in me is a music montage of a young woman’s experience of verbal abuse suffered from her boss and physical abuse from her fiancé. She makes it through a journey from defeat to empowerment.",
  },
  {
    id: 5,
    title: "Things are not what they seem!",
    image: notWhatTheySeemPic,
    video: notWhatTheySeemVid,
    year: "2018",
    description:
      "Things are not what they seem! Uses misdirection to show how we may assume that many teenagers bully and put each other down, but all is not what it may seem.  There are times we are witnessing tough love expressed by teens to show each other that they care.",
  },
  {
    id: 6,
    title: "1 Size Does Not Fit All",
    image: oneSizePic,
    video: oneSizeVid,
    year: "2018",
    description:
      "What are you paying attention to? Shows that it is unwise to judge a book by its cover. It may be easy to assume that you need to be “on-guard” when you see a minority man approach you and completely “off-guard” when you are approach by a beautiful young lady. However, she might be the vixen that is really out to get you.",
  },
  {
    id: 7,
    title: "We Came To Party!!!",
    image: cameToPartyPic,
    video: cameToPartyVid,
    year: "2018",
    description:
      "What are you paying attention to? Shows that it is unwise to judge a book by its cover. It may be easy to assume that you need to be “on-guard” when you see a minority man approach you and completely “off-guard” when you are approach by a beautiful young lady. However, she might be the vixen that is really out to get you.",
  },
  {
    id: 8,
    title: "You Matter",
    image: youMatterPic,
    video: youMatterVid,
    year: "2018",
    description:
      "What are you paying attention to? Shows that it is unwise to judge a book by its cover. It may be easy to assume that you need to be “on-guard” when you see a minority man approach you and completely “off-guard” when you are approach by a beautiful young lady. However, she might be the vixen that is really out to get you.",
  },
];

export default { movieData };