import React from "react";
import logo from "../Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="bg-black text-white py-16 flex flex-col">
      <div className="grid grid-cols-7">
        <div className="col-span-1 lg:col-span-2"></div>
        <div className="col-span-5 lg:col-span-3">
          <div className="grid-span-5 flex flex-col text-center ">
            <div className="justify-center flex">
              <div>
                <img className="h-32 " src={logo} alt="" />
              </div>
            </div>
            <div className="text-4xl lg:text-5xl pb-4 s2s-text gold">Soul2Soulz</div>
            <div className="pb-4 s2s-text text-lg gold">Find out more!</div>
          </div>
          <div className="flex flex-col text-center">
            <div className="flex justify-around content-center py-4 md:px-16">


          {/* ***** */}
          {/* Phone */}
          {/* ***** */}
          
              <a href="tel:3473168444">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="gold phone-hover"
                  size="3x"
                />
              </a>
            

          {/* ****** */}
          {/* E-Mail */}
          {/* ****** */}

              <a href="mailto: email@soul2soulz.org">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="gold email-hover"
                  size="3x"
                />
              </a>

            {/* ******** */}
            {/* FaceBook */}
            {/* ******** */}

              <a href="https://www.facebook.com/Soul2Soulz" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className="gold facebook-hover"
                  size="3x"
                />
              </a>

          {/* ********* */}
          {/* Instagram */}
          {/* ********* */}

              <a href="https://www.instagram.com/soul_2_soulz/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="gold instagram-hover"
                  size="3x"
                />
              </a>

          {/* ******* */}
          {/* Twitter */}
          {/* ******* */}
              <a href="https://twitter.com/Soul2Soulz" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="gold twitter-hover"
                  size="3x"
                />
              </a>

            </div>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-2"></div>
      </div>
    </div>
  );
};

export default Footer;