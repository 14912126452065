import React, {useEffect} from "react";
import { movieData } from "./movieData";
import { NavLink } from "react-router-dom";

const Movies = (props) => {
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  return (
    <div className="flex flex-col items-center pt-20 goldbg">
      <div className="pt-5 text-5xl s2s-header">FILMS</div>
      <div className="flex flex-row flex-wrap pt-5 items-center w-full justify-center">
        {movieData.map((movie, key) => (
          <NavLink id={movie.id} to={`films/${movie.id}`} className="no-underline underline-none md:w-1/2 lg:w-1/3 " >
            <div className="text-center justify-center items-center h-full">
                <img
                  className="mx-auto w-2/3 md:w-2/3 lg:w-7/8 transform duration-500 hover:scale-105 z-0"
                  src={movie.image}
                  alt=""
                />
                <div className="text-lg text-red-600 s2s-text">Click Poster to Watch</div>
              </div>
              <div className=" text-2xl flex-end s2s-text text-black pb-10 text-center">{movie.title}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Movies;
