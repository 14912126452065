import React from "react";
import Burger from "./Burger";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div
      className="flex flex-row align-items-center bg-black justify-between fixed w-screen py-4 z-50"
      id="navbar"
    >
      <div className="pl-4 lg:pl-24">
        <NavLink exact to={"/"}>
          <img className="w-10" src={logo} alt="logo" />
        </NavLink>
      </div>

      <div className="flex align-items-center items-center hidden xl:flex xl:flex-row lg:flex lg:flex-row lg:pr-24 ">
        <div>
          <NavLink className="gold pr-20 s2s-header text-lg underline-none hover-red" exact to={"/biography"}>
            BIOGRAPHY
          </NavLink>
        </div>

        <div>
          <NavLink className="gold pr-20 s2s-header text-lg underline-none hover-red" exact to={"/demo"}>
          DEMO REEL
          </NavLink>
        </div>

        <div>
          <NavLink className="gold pr-20 s2s-header text-lg underline-none hover-red" exact to={"/films"}>
            FILMS
          </NavLink>
        </div>

        <div>
          <NavLink className="gold pr-20 s2s-header text-lg underline-none hover-red" exact to={"/contact"}>
            CONTACT
          </NavLink>
        </div>
      </div>

      <div className="lg:hidden flex align-items-center">
        <Burger />
      </div>
    </div>
  );
}
