import React from "react";
import Home from "./Home";
import Bio from "./Bio";
import Movies from "./Movies";
import Contact from "./Contact";
import NotFound from "./NotFound";
import { HashRouter, Route, Switch } from "react-router-dom";
import MovieDetails from "./MovieDetails";
import Demo from "./Demo";

const Main = (props) => {
  return (
    <div>
      <HashRouter>
      <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/biography">
        <Bio />
      </Route>

      <Route exact path="/demo">
        <Demo />
      </Route>

      <Route exact path="/films">
        <Movies />
      </Route>

      <Route exact path="/contact">
        <Contact />
      </Route>

      <Route
        exact
        path="/films/:id"
        component={(match) => <MovieDetails match={match} />}
      />
      <Route component={NotFound} />
      </Switch>
      </HashRouter>
    </div>
  );
};

export default Main;
