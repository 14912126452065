import React, {useEffect} from 'react';
import { NavLink } from "react-router-dom"

const NotFound = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      });
    return (
        <div className="py-32 goldbg text-center">
            <div className="s2s-header text-4xl">Error 404</div>
            <div className="s2s-header text-6xl">Page Not Found</div>
            <NavLink exact to="/" className="text-lg text-red-600 no-underline underline-none s2s-text">Return Home</NavLink>
        </div>
    );
}

export default NotFound;